export const drawerStateReducer = (state, action) => {
  switch (action.type) {
    case "open": {
      localStorage.setItem("drawerState", "1")
      return { ...state, drawerState: 1 }
      //      return { drawerState: 1 }
    }
    case "close": {
      localStorage.setItem("drawerState", "0")
      return { ...state, drawerState: 0 }
      //      return { drawerState: 0 }
    }
    default: {
      throw new Error(`Unhandled action type ${action.type}`)
    }
  }
}
