// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-templates-tool-page-js": () => import("./../../../src/components/templates/toolPage.js" /* webpackChunkName: "component---src-components-templates-tool-page-js" */),
  "component---src-components-templates-tools-by-category-js": () => import("./../../../src/components/templates/toolsByCategory.js" /* webpackChunkName: "component---src-components-templates-tools-by-category-js" */),
  "component---src-components-templates-tools-by-list-js": () => import("./../../../src/components/templates/toolsByList.js" /* webpackChunkName: "component---src-components-templates-tools-by-list-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-categories-js": () => import("./../../../src/pages/categories.js" /* webpackChunkName: "component---src-pages-categories-js" */),
  "component---src-pages-favorites-js": () => import("./../../../src/pages/favorites.js" /* webpackChunkName: "component---src-pages-favorites-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lists-js": () => import("./../../../src/pages/lists.js" /* webpackChunkName: "component---src-pages-lists-js" */)
}

