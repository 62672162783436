/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
import React from "react"
import { DrawerStateProvider } from "./src/context/appContext"
import { FavStateProvider } from "./src/context/favoriteContext"

// You can delete this file if you're not using it
export const onClientEntry = () => {
  if (window.location.pathname === "/") {
    window.location.pathname = `/de`
  }
}

export const wrapRootElement = ({ element }) => {
  return (
    <DrawerStateProvider>
      <FavStateProvider>{element}</FavStateProvider>
    </DrawerStateProvider>
  )
}
