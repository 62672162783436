import React from "react"
import { drawerStateReducer } from "../reducers/layoutReducer"

export const AppStateContext = React.createContext()
export const AppDispatchContext = React.createContext()

const initialState = () => {
  if (typeof window !== `undefined`) {
    const drawerState = Number(window.localStorage.getItem("drawerState") || 0)
    return { drawerState }
  }
  // if window is undefined (on build and refresh)
  else {
    return { drawerState: 0 }
  }
}
const DrawerStateProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(
    drawerStateReducer,
    { drawerState: 0 },
    initialState
  )

  return (
    <AppStateContext.Provider value={state}>
      <AppDispatchContext.Provider value={dispatch}>
        {children}
      </AppDispatchContext.Provider>
    </AppStateContext.Provider>
  )
}

export { DrawerStateProvider }
