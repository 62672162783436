export const favoriteReducer = (state, action) => {
  switch (action.type) {
    case "addFav": {
      let favs
      !state.favorites.includes(action.payload)
        ? (favs = state.favorites.concat(action.payload))
        : (favs = state.favorites)
      localStorage.setItem("favorites", JSON.stringify(favs))
      return { ...state, favorites: favs }
    }

    case "removeFav": {
      const favs = state.favorites.filter(
        favorite => favorite !== action.payload
      )
      localStorage.setItem("favorites", JSON.stringify(favs))
      return { ...state, favorites: favs }
    }
    default: {
      throw new Error(`Unhandled action type ${action.type}`)
    }
  }
}
