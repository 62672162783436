import React from "react"
import { favoriteReducer } from "../reducers/favoriteReducer"

export const FavStateContext = React.createContext()
export const FavDispatchContext = React.createContext()

const initialState = () => {
  if (typeof window !== `undefined`) {
    const favorites = JSON.parse(localStorage.getItem("favorites")) || []
    return { favorites: favorites }
  }
  // if window is undefined (on build and refresh)
  else {
    return { favorites: [] }
  }
}
const FavStateProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(
    favoriteReducer,
    { favorites: [] },
    initialState
  )

  return (
    <FavStateContext.Provider value={state}>
      <FavDispatchContext.Provider value={dispatch}>
        {children}
      </FavDispatchContext.Provider>
    </FavStateContext.Provider>
  )
}

export { FavStateProvider }
